<!--
 * @Description: 客房营业汇总
 * @Author: 琢磨先生
 * @Date: 2022-10-08 10:27:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-08 10:28:25
-->
<template>
    <div>

    </div>
</template>

<script>
    export default {
        
    }
</script>

<style   scoped>

</style>